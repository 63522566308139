<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import "flatpickr/dist/flatpickr.css";

import useVuelidate from "@vuelidate/core";

import Layout from "../../layouts/main.vue";
import appConfig from "../../../app.config";
import PageHeader from "@/components/page-header";

import store from "../../state/store";

import { VueEditor } from "vue3-editor";
import RequestTrackingServices from "../../services/RequestTrackingServices";

export default {
  page: {
    title: "Ajouter un colis",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      title: "Ajouter un colis",
      items: [
        {
          text: "Suivi de colis",
          href: "/",
        },
        {
          text: "Ajouter un colis",
          active: true,
        },
      ],
      loading: false,
      fields: {
        code: "",
        title: "",
        type: "normal",
        description: "",
        client_infos: { fullname: "", phone: "" },
        registered_at: new Date()
          .toISOString()
          .replace("T", " ")
          .split(".")[0]
          .slice(0, 16),
      },
    };
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    VueEditor,
  },

  beforeMount() {},
  methods: {
    check_validation() {
      if (!this.fields.title.trim()) {
        alert("Veuillez renseigner le nom du colis");
        return false;
      }

      if (!this.fields.type.length) {
        alert("Veuillez renseigner le type du colis");
        return false;
      }

      if (!this.fields.description.length) {
        alert("Veuillez renseigner la description du colis");
        return false;
      }

      return true;
    },
    async handleSubmit() {
      store.dispatch("setGlobalLoading", true);

      if (!this.check_validation()) {
        store.dispatch("setGlobalLoading", false);
        return false;
      }

      RequestTrackingServices.create(this.fields)
        .then((data) => {
          alert("Le colis a bien été ajouté !");
          this.$router.push("/request-details/" + data.tracking.id);
        })
        .catch((er) => {
          console.log(er);
          alert("Une erreur est survenue lors de l'ajout du colis");
        })
        .finally(() => {
          store.dispatch("setGlobalLoading", false);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-8">
        <form @submit.prevent="handleSubmit">
          <div class="card">
            <div class="card-body">
              <div class="mb-3">
                <label class="form-label" for="product-title-input">
                  Code de suivi
                  <small>
                    (Laissez vide si vous voulez le générer automatiquement)
                  </small>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="product-title-input"
                  placeholder="Entrez le code de suivi"
                  v-model="fields.code"
                />
              </div>

              <div class="form-group mb-3">
                <label class="form-label" for="product-title-input">
                  Nature du colis
                </label>

                <Multiselect
                  class="form-control"
                  v-model="fields.type"
                  :close-on-select="false"
                  :searchable="true"
                  :create-option="true"
                  :options="[
                    ...[
                      { value: 'normal', label: 'Normal' },
                      { value: 'sensible', label: 'Sensible' },
                    ],
                  ]"
                />
              </div>

              <div class="row mb-4">
                <div class="col-12 mb-4">
                  <label class="form-label" for="product-title-input">
                    Libellé du colis
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="product-title-input"
                    placeholder="Entrez le libellé du colis"
                    v-model="fields.title"
                  />
                </div>

                <div class="col-12">
                  <label class="form-label" for="product-title-input">
                    Date d'enregistrement du colis
                  </label>
                  <input
                    type="datetime-local"
                    class="form-control"
                    id="product-title-input"
                    placeholder="Entrez la date"
                    v-model="fields.registered_at"
                  />
                </div>
              </div>

              <div>
                <label>Description du colis</label>
                <VueEditor v-model="fields.description" />
              </div>
            </div>
          </div>
          <!-- end card -->

          <!-- end card -->
          <div class="text-end mb-3">
            <button type="submit" class="btn btn-success w-sm">
              Enregistrer le colis
            </button>
          </div>
        </form>
      </div>
      <!-- end col -->

      <div class="col-lg-4">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Informations sur le client</h5>
          </div>
          <div class="card-body">
            <div class="mb-3">
              <label for="choices-publish-status-input" class="form-label">
                Nom complet
              </label>
              <input
                v-model="fields.client_infos.fullname"
                class="form-control"
              />
            </div>
            <div class="mb-3">
              <label for="choices-publish-status-input" class="form-label">
                Numéro de téléphone
              </label>
              <input v-model="fields.client_infos.phone" class="form-control" />
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
.drop-zone {
  width: 96%;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.2);
}
.drop-zone:hover {
  border: 1px solid rgba(0, 0, 0, 0.6);
  color: rgba(0, 0, 0, 0.6);
}
</style>
